import Color from "colorjs.io";

export const useColors = () => {
  const colorMode = useColorMode();

  function setLuminance(color, options) {
    if (!color) {
      return "#000000";
    }

    const clr = new Color(color);

    clr.set({
      "lch.l": options?.luminance || (colorMode.value == "light" ? 44 : 27),
    });

    return clr.toString({
      precision: options?.precision || 5,
      format: options?.format || "hex",
    });
  }

  return {
    setLuminance,
  };
};
